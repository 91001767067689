import React, {useState, useEffect} from 'react'
import {graphql} from 'gatsby'
import {mapEdgesToNodes} from '../lib/helpers'
import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'
import Layout from '../containers/layout'
import Container from '../components/container'
import SEO from '../components/seo'
import {animateScroll as scroll} from 'react-scroll'
import SweetScroll from 'sweet-scroll'

import About from '../components/subpages/About'
import Contact from '../components/subpages/Contact'
import Testimonial from '../components/subpages/Testimonial'
import Hero from '../components/hero/Hero'
import Technique from '../components/subpages/Technique'
import HeroLogo from '../svg/hero_logo.svg'
import QuoteIcon from '../components/icon/quote'
import CountUp from 'react-countup'

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title {
          _key
          _type
          en
          de
        }
      description
      keywords
      callToActionActive
      callToActionButtonText
      callToActionText
      heroQuoteCredit
      _rawHeroQuote
      mainImage {
        alt
        caption
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid(sizes: "(max-width: 2000px) 100vw, 2000px") {
            ...GatsbySanityImageFluid
          }
          _id
        }
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
      }
      seperatorImage {
        alt
        caption
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }


    attentionQuotes: sanityAttentionQuotes(_id: {regex: "/(drafts.|)attentionQuotes/"}) {
      attentionnumber1
      attentionnumber2
      attentionnumber3
      attentionquote1
      attentionquote2
      attentionquote3
    }

    testimonials: allSanitySampleProject {
      edges {
        node {
        id
        testimonialCards {
          _key
          _type
          title
          knownfrom {
            _key
            _type
            role
            show
            place
            producer
          }
          mainImage {
            alt
            caption
            copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
            ...GatsbySanityImageFluid
          }
              _id
            }
          }
          excerpt {
            _key
            _type
            de {
              _key
              _type
              style
              list
            }
            en {
              _key
              _type
              style
              list
            }
          }
        }
        _rawTestimonialCards
      }
      }
    }


    posts: allSanityPost(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
            ...GatsbySanityImageFluid
          }
            }
            alt
          }
          title {
          _key
          _type
          en
          de
        }
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }

    about: sanityPage(_id: { regex: "/(drafts.|)about/" }) {
      id
      title {
          _key
          _type
          en
          de
        }
      _rawBody
      mainImage {
        alt
        caption
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    technique: sanityPage(_id: { regex: "/(drafts.|)technique/" }) {
      id
      title {
          _key
          _type
          en
          de
        }
      _rawBody
      mainImage {
        alt
        caption
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    contact: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      id
      title {
          _key
          _type
          en
          de
        }
      _rawBody
      mainImage {
        alt
        caption
        copyright {
              _key
              _type
              photographer {
                _key
                _type
                name
                slug {
                  _key
                  _type
                  current
                }
              }
            }
        asset {
          fluid(sizes: "(max-width: 1200px) 100vw, 1200px") {
            ...GatsbySanityImageFluid
          }
        }
      }
    }

    menu:  sanityMenu(_id: {regex: "/(drafts.|)menu/"}) {
      id
      menuitems {
        _key
        _type
        name {
          _key
          _type
          de
          en
        }
        url
        menutype
      }
      menuName
    }

    socialMedia: sanitySocialMedia {
      id
      socialMediaItems {
        _key
        _type
        url
        name
      }
    }

  }
`

const IndexPage = props => {
  const {data, errors, pageContext} = props

  const site = (data || {}).site
  const attentionQuotes = (data || {}).attentionQuotes
  const menu = (data || {}).menu
  const about = (data || {}).about
  const technique = (data || {}).technique
  const contact = (data || {}).contact
  const socialMedia = (data || {}).socialMedia

  if (errors) {
    return (
      <Layout language={pageContext.intl.language} menus={menu}>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  const testimonials =
    data &&
    data.testimonials &&
    mapEdgesToNodes(data.testimonials)

  // --- attention quotes functionality ---
  const [start, setStart] = useState(false)

  useEffect(() => {
    const checkScrollValue = () => {
      if (!start && window.pageYOffset > 650) {
        setStart(true)
      }
    }

    window.addEventListener('scroll', checkScrollValue)
    return () => {
      window.addEventListener('scroll', checkScrollValue)
    }
  }, [start])

  const handleCallToAction = () => {
    setCallToAction({...callToAction, toggled: false})
    const scroller = new SweetScroll()
    scroller.to(10000)
  }

  const closeCallToAction = () => {
    setCallToAction({...callToAction, toggled: false})
  }

  const [callToAction, setCallToAction] = useState({
    toggled: site.callToActionActive,
    headline: site.callToActionText,
    buttonText: site.callToActionButtonText,
    handleCallToAction,
    closeCallToAction
  })

  return (
    <>
      <Layout language={pageContext.intl.language} menus={menu} pageContext={pageContext} site={site} callToAction={callToAction} socialMedia={socialMedia} >
        {pageContext.intl.language === 'de'
          ? <SEO title={site.title.de} description={site.description} keywords={site.keywords} />
          : <SEO title={site.title.en} description={site.description} keywords={site.keywords} />
        }

        <div className={`hero--wrapper ${callToAction.toggled ? 'callToActionActive' : ''}`}>
          <Hero fluid={site.mainImage.asset.fluid} mainImage={site.mainImage}>
            <div className='herotext--wrapper'>
              <h1 className='h1 inline-grid--hero'>
                <div className='hero-logowrapper'>
                  <HeroLogo />
                </div>
              </h1>
              <div className='quotes'>
                <div className='quote'>
                  <QuoteIcon />
                </div>
                <BlockContent blocks={site._rawHeroQuote || []} />
                <h4 className='quote-credit'>{site.heroQuoteCredit}</h4>
              </div>

            </div>
          </Hero>
        </div>
        <Container>
          <h1 hidden>Welcome to {site.title.en}</h1>
          <>
            <div className='attention-desktop'>
              <div className='attention--grid '>
                <div className='divider attention--header'>

                  <h1 className='h1 grid--hero '>
                    <span className='attention'>Christian Schleicher</span>
                  </h1>
                </div>

                <div className='columns column1'>

                  <span className='number'>
                    {start ? <CountUp end={parseInt(attentionQuotes.attentionnumber1)} /> : 0}
                  </span>
                  <h1>{attentionQuotes.attentionquote1}</h1>
                </div>

                <div className='columns column2'>
                  <span className='number'>{start ? <CountUp end={parseInt(attentionQuotes.attentionnumber2)} /> : 0}</span>
                  <h1>{attentionQuotes.attentionquote2}</h1>
                </div>
                <div className='columns column3'>
                  <span className='number'>{start ? <CountUp end={parseInt(attentionQuotes.attentionnumber3)} /> : 0}</span>
                  <h1>{attentionQuotes.attentionquote3}</h1>
                </div>
              </div>
            </div>

            <div className='attention-mobile'>

              <div className='attention--grid '>
                <div className='divider attention--header'>

                  <h1 className='h1 grid--hero '>
                    <span className='attention'>Christian Schleicher</span>
                  </h1>
                </div>
                <div className='columns column1'>

                  <span className='number'>
                    <CountUp end={parseInt(attentionQuotes.attentionnumber1)} />
                  </span>
                  <h1>{attentionQuotes.attentionquote1}</h1>
                </div>

                <div className='columns column2'>
                  <span className='number'><CountUp end={parseInt(attentionQuotes.attentionnumber2)} /></span>
                  <h1>{attentionQuotes.attentionquote2}</h1>
                </div>
                <div className='columns column3'>
                  <span className='number'><CountUp end={parseInt(attentionQuotes.attentionnumber3)} /></span>
                  <h1>{attentionQuotes.attentionquote3}</h1>
                </div>

              </div>

            </div>

            <About
              title='Uber Christian'
              about={about}
              browseMoreHref='/about/'
              language={pageContext.intl.language}
            />
            <Technique
              title='Gesangs Technik'
              technique={technique}
              image={site.seperatorImage}
              browseMoreHref='/technique/'
              language={pageContext.intl.language}
            />
            {/* // If we want a blog in the future */}
            {/* <BlogPostPreviewGrid
            title='Blog'
            nodes={postNodes}
            browseMoreHref='/blog/'
            language={pageContext.intl.language}
          /> */}

            <Testimonial
              items={testimonials} title='Testimonials'
              language={pageContext.intl.language}
            />
            <Contact
              title='Contact page'
              contact={contact}
              browseMoreHref='/contact/'
              language={pageContext.intl.language}
            />
          </>
        </Container>
      </Layout>
    </>
  )
}

export default IndexPage
