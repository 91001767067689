
/* eslint-disable prettier/prettier */
/* eslint-disable react/state-in-constructor */
import React, {useState} from 'react'
import MobileStepper from '@material-ui/core/MobileStepper'

import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import SwipeableViews from 'react-swipeable-views'

import {autoPlay} from 'react-swipeable-views-utils'
import withStyles from '@material-ui/styles/withStyles'
import {buildImageObj} from '../../lib/helpers'
import {imageUrlFor} from '../../lib/image-url'
import QuoteIcon from '../icon/quote'
import BlockContent from '../block-content'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const styles = {
  cardMedia: {
    height: '300px'
  },
  sliderWrapper: {
    width: '1000px'
  }
}

const Testimonial = (props) => {
  const initstate = {
    activeStep: 0
  }

  const [state, setState] = useState(initstate)
  const {activeStep} = state
  const {items, title, language} = props
  const maxSteps = items[0].testimonialCards.length

  const handleNext = () => {
    if (state.activeStep === maxSteps - 1) {
      setState(() => ({
        activeStep: 0
      }))
    } else {
      setState(prevState => ({
        activeStep: prevState.activeStep + 1
      }))
    }
  }

  const handleBack = () => {
    setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }))
  }

  const handleStepChange = activeStep => {
    setState({activeStep})
  }

  return (
    <section className='testimonial' id='testimonial'>
      <h1>{title}</h1>
      <div className='slider'>

        <AutoPlaySwipeableViews
          axis='x'
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={8000}
          style={styles.sliderWrapper}
          slideClassName='slide'
          springConfig={{duration: '1s', easeFunction: 'ease-in', delay: '0s'}}
        >

          {items[0].testimonialCards.map((item, index) => {
            const {
              title,
              knownfrom,
              mainImage
            } = item

            return (
              <div key={index} className='tile tile-shadow'>

                {Math.abs(activeStep - index) <= 2 ? (

                  <div className='testimonial-wrapper'>
                    <div className='testimonial-image'>

                      <div className='testimonial-image--normal'>

                        {mainImage && mainImage.asset && (
                          <img
                            src={imageUrlFor(buildImageObj(mainImage)).url()}
                            title={`${mainImage.caption} | Photo Copyright: ${mainImage.copyright.photographer.name}`}
                            alt={mainImage.alt}
                            caption={mainImage.caption}
                          />
                        )}
                      </div>

                      <div className='testimonial-image--mobile'>

                        {mainImage && mainImage.asset && (
                          <img
                            src={imageUrlFor(buildImageObj(mainImage))
                              .width(500)
                              .height(Math.floor((9 / 16) * 700))
                              .url()}
                            title={`${mainImage.caption} | Photo Copyright: ${mainImage.copyright.photographer.name}`}
                            alt={mainImage.alt}
                            caption={mainImage.caption}
                          />
                        )}
                      </div>
                    </div>
                    <div className='testimonial-text'>
                      <QuoteIcon />
                      {
                        items[0]._rawTestimonialCards.map((rawitem, excerptIndex) => {
                          const {
                            excerpt
                          } = rawitem

                          const excerptDe = excerpt.de.filter(function (user) {
                            return user._key === item.excerpt.de[0]._key
                          })

                          return <BlockContent key={excerptIndex} blocks={excerptDe || []} />
                        })
                      }

                      {/* {
                        // For Dual Language

                        items[0]._rawTestimonialCards.map((rawitem, excerptIndex) => {
                          const {
                            excerpt
                          } = rawitem

                          const excerptDe = excerpt.de.filter(function (user) {
                            return user._key === item.excerpt.de[0]._key
                          })

                          const excerptEn = excerpt.en.filter(function (user) {
                            return user._key === item.excerpt.en[0]._key
                          })

                          return <BlockContent key={excerptIndex} blocks={
                            language === 'de' ? excerptDe : excerptEn || []} />
                        })
                      } */}
                      <Typography gutterBottom variant='h5' component='h2'>
                        {title}
                      </Typography>
                      {
                        knownfrom.map((known, key) => {
                          return (
                            <Typography key={key} variant='subtitle2' className='testimonial-subtitle' component='h6'>
                              <div className='knownfroms'>

                                <div className='part'>{known.role}</div>
                                {
                                  known.producer
                                    ? <div className='producer'>{known.producer}</div>
                                    : null
                                }
                                <div className='show'>{known.show}</div>

                                <div className='theatre'>
                                  {known.place}
                                </div>
                              </div>

                            </Typography>
                          )
                        })
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            )
          })}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position='static'
          activeStep={activeStep}
          nextButton={
            <Button
              size='small'
              onClick={handleNext}
            >
              Next
            </Button>
          }
          backButton={
            <Button
              size='small'
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              Back
            </Button>
          }
        />
      </div>
    </section>
  )
}

export default withStyles(styles)(Testimonial)
