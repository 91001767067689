import React from 'react'
import Img from 'gatsby-image'
import BlockContent from '../block-content'

const Technique = props => {
  const {technique, image, language} = props

  return (
    <section className='section--technique'>

      <div className='technique--wrapper' id='technique'>

        <div className='hero--divider'>
          <div className='section-image'>
            <Img
              loading='lazy'
              fluid={image.asset.fluid}
              title={`${image.caption} | Photo Copyright: ${image.copyright}`}
              alt={image.alt}
            />
          </div>

          <div className='section-body'>
            {language === 'de'
              ? <>
                <h1>{technique.title.de}</h1>
                <BlockContent blocks={technique._rawBody.de || []} />
              </>
              : <>
                <h1>{technique.title.en}</h1>
                <BlockContent blocks={technique._rawBody.en || []} />
              </>
            }
          </div>

        </div>

        <div className='technique--mobile'>

          <div className='section-image'>
            <Img
              loading='lazy'
              fluid={image.asset.fluid}
              title={`${image.caption} | Photo Copyright: ${image.copyright}`}
              alt={image.alt}
            />
          </div>

          <div className='section-body'>
            {language === 'de'
              ? <>
                <h1>{technique.title.de}</h1>
                <BlockContent blocks={technique._rawBody.de || []} />
              </>
              : <>
                <h1>{technique.title.en}</h1>
                <BlockContent blocks={technique._rawBody.en || []} />
              </>
            }
          </div>

        </div>

      </div>

    </section>
  )
}

export default Technique
