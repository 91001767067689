/* eslint-disable prettier/prettier */

import React from 'react'

import BlockContent from '../block-content'
import Img from 'gatsby-image'

const Contact = props => {
  const {contact} = props

  return (
    <section className='section--contact'>

      <div className='contact--wrapper' id='contact'>

        <div className='section-body'>

          <form
            className='contact-form card'
            name='contact'
            method='POST'
            netlify-honeypot='bot-field'
            data-netlify='true'
            action='/thanks'
          >
            <h1>{contact.title.de}</h1>
            <BlockContent blocks={contact._rawBody.de || []} />

            <input type='hidden' name='form-name' value='contact' />
            <input type='hidden' name='subject' value='Contact form: christianschleicher.de' />
            <p className='hidden'>
              <label>Don’t fill this out if you're human: <input name='bot-field' /></label>
            </p>
            <p className='form-field'>
              <label htmlFor='name' className='control-label'>
                <span className='label'>Deine Name</span>
                <input type='text' name='name' required='required' />
              </label>

            </p>
            <p className='form-field'>
              <label htmlFor='email' className='control-label'>
                <span className='label'>Deine Emailadresse</span>
                <input type='email' name='email' required='required' />
              </label>

            </p>
            <p className='form-field'>
              <span htmlFor='message' className='label'>Deine Nachricht</span>
              <textarea required='required' name='message' />
            </p>
            <p className='form-field checkbox-datenschutz'>
              <input htmlFor='datenschutz' type='checkbox' name='datenschutz' id='datenschutz' required='required' />
              <span className='label'>
                Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage umgehend gelöscht. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung. * </span>
            </p>
            <div className='button-container'>
              <button type='submit' className='button'>
                <span>Senden</span>
              </button>
            </div>
          </form>

        </div>
        <div className='section-image'>
          <Img
            loading='lazy'
            fluid={contact.mainImage.asset.fluid}
            title={`${contact.mainImage.caption} | Photo Copyright: ${contact.mainImage.copyright}`}
            alt={contact.mainImage.alt}
          />
        </div>
      </div>
    </section>
  )
}

export default Contact
